
import { computed, defineComponent, onBeforeMount, onUnmounted } from 'vue';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import UserModule from '@/store/user';
import SettingsModule from '@/store/settings';
import WorkMonthsModule from '@/store/settings/workMonths';

import { GuiLoader, GuiButton, GuiTextInput } from '@library/gigant_ui';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import FormInput from '@/components/form/Input/index.vue';

library.add(faChevronLeft, faChevronRight);

export default defineComponent({
  components: {
    GuiLoader,
    GuiButton,
    GuiTextInput,
    TabsNav,
    AppRow,
    AppCol,
    AppFieldset,
    FormInput,
  },
  setup() {
    const tabsNav = computed(() => SettingsModule.tabsNav);
    const currentTab = computed(() => SettingsModule.allTabs.workMonths.id);
    const isLoading = computed(() => WorkMonthsModule.isLoading);
    const isSaving = computed(() => WorkMonthsModule.isSaving);
    const workMonths = computed(() => {
      return isLoading.value
        ? WorkMonthsModule.model[WorkMonthsModule.previousYear]
        : WorkMonthsModule.model[WorkMonthsModule.year];
    });

    const canEdit = computed(() => UserModule.userHasPermission('CAN_EDIT_WORK_MONTH'));

    const year = computed(() => `${WorkMonthsModule.year} год`);
    const minYear = +moment().add(-50, 'years').format('YYYY');
    const maxYear = +moment().add(50, 'years').format('YYYY');

    function changeYear(increment: boolean): void {
      if (isLoading.value) {
        return;
      }

      WorkMonthsModule.changeYear(increment);
    }

    function updateMonth(month: { workHours: string }, hours: string): void {
      const newValue = (parseInt(hours) || 0).toString();
      month.workHours = newValue;
    }

    function save() {
      WorkMonthsModule.saveYear();
    }

    function cancel() {
      WorkMonthsModule.resetYear();
    }

    onBeforeMount(async () => {
      await SettingsModule.initTabsItems();
      await WorkMonthsModule.init();
    });

    onUnmounted(() => {
      WorkMonthsModule.resetStore();
    });

    return {
      tabsNav,
      currentTab,
      year,
      minYear,
      maxYear,
      workMonths,
      isLoading,
      isSaving,
      canEdit,
      changeYear,
      save,
      cancel,
      updateMonth,
    };
  },
});
