import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00a148c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "work-year" }
const _hoisted_3 = { class: "work-month-list cp-margin__t--24" }
const _hoisted_4 = { class: "work-month__label" }
const _hoisted_5 = {
  key: 1,
  class: "cp-text__text-primary work-month__input"
}
const _hoisted_6 = {
  key: 1,
  class: "cp-margin__t--8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_gui_text_input = _resolveComponent("gui-text-input")!
  const _component_app_fieldset = _resolveComponent("app-fieldset")!
  const _component_gui_button = _resolveComponent("gui-button")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper cp-page__competence"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, {
        grow: 0,
        ref: "header"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            (parseInt(_ctx.year) > _ctx.minYear)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: ['fas', 'chevron-left'],
                  size: "sm",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeYear(false)))
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_form_input, {
              class: "work-year__input cp-margin__bottom-0 cp-padding__t-0",
              modelValue: _ctx.year,
              disabled: ""
            }, null, 8, ["modelValue"]),
            (parseInt(_ctx.year) < _ctx.maxYear)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 1,
                  icon: ['fas', 'chevron-right'],
                  size: "sm",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeYear(true)))
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 512),
      (_ctx.isLoading && !_ctx.workMonths)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { absoluteCenter: "" })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_app_col, {
            key: 1,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_row, { class: "block-radius" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_col, { class: "cp-padding__l--8 cp-padding__b--8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_fieldset, {
                        class: _normalizeClass(["cp-margin__t--8 work-month-page", { 'work-month-page_readonly': !_ctx.canEdit }])
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode("Предельная выработка по месяцам, часов")
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workMonths, (month) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: month.start + month.id,
                                class: "work-month"
                              }, [
                                _createElementVNode("div", _hoisted_4, _toDisplayString(month.label), 1),
                                (_ctx.canEdit)
                                  ? (_openBlock(), _createBlock(_component_gui_text_input, {
                                      key: 0,
                                      class: "work-month__input",
                                      "model-value": month.workHours,
                                      view: "rounded",
                                      disabled: _ctx.isSaving || _ctx.isLoading,
                                      "reg-exp-value": /^\d{1,3}$/,
                                      onUpdate: ($event: any) => (_ctx.updateMonth(month, $event)),
                                      hint: month.workHours && month.isChanged ? 'Изменено' : ''
                                    }, null, 8, ["model-value", "disabled", "onUpdate", "hint"]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(month.workHours), 1))
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      (_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_gui_loader, {
                            key: 0,
                            absoluteCenter: ""
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.canEdit)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_gui_button, {
                              class: "cp-margin__r--sx",
                              disabled: _ctx.isSaving || _ctx.isLoading,
                              onBtnClick: _ctx.save
                            }, {
                              after: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["cp-transition cp-relative", { 'cp-padding__l': _ctx.isSaving }])
                                }, [
                                  (_ctx.isSaving)
                                    ? (_openBlock(), _createBlock(_component_gui_loader, {
                                        key: 0,
                                        inline: "",
                                        absoluteCenter: ""
                                      }))
                                    : _createCommentVNode("", true)
                                ], 2)
                              ]),
                              default: _withCtx(() => [
                                _createTextVNode(" Сохранить ")
                              ]),
                              _: 1
                            }, 8, ["disabled", "onBtnClick"]),
                            _createVNode(_component_gui_button, {
                              class: "cp-margin__r--sx",
                              view: "outlined",
                              color: "primary",
                              label: "Отмена",
                              disabled: _ctx.isSaving || _ctx.isLoading,
                              onBtnClick: _ctx.cancel
                            }, null, 8, ["disabled", "onBtnClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_col)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}