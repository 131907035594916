import moment from 'moment';
import { Action, Module, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { getWorkMonths, updateYear } from '@/api/workMonths';
import { strings } from '@/lib/stringConst';
import { CatchFormResponse } from '@/interfaces/shared';
import { WorkMonth } from '@/interfaces/workMonth.interface';

type YearModel = { months: WorkMonth[]; year: number };

export const MODULE_NAME = 'workMonths';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class WorkMonthsModule extends PageBaseModule {
  isLoading = true;
  isSaving = false;

  year = parseInt(moment().format('YYYY'));
  previousYear = this.year;
  preservedModel = {} as Record<string, WorkMonth[]>;
  model = {} as Record<string, WorkMonth[]>;

  constructor(module: WorkMonthsModule) {
    super(module);
  }

  @Mutation
  UPDATE_IS_LOADING(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  UPDATE_IS_SAVING(value: boolean) {
    this.isSaving = value;
  }

  @Mutation
  UPDATE_YEAR(value: number) {
    this.previousYear = this.year;
    this.year = value;
  }

  @Mutation
  RESET_YEAR() {
    this.year = parseInt(moment().format('YYYY'));
  }

  @Mutation
  UPDATE_MODEL({ months, year, isPreserved = false }: YearModel & { isPreserved: boolean }) {
    const model = isPreserved ? this.preservedModel : this.model;
    const cloneMonth = (month: WorkMonth) =>
      Object.create(Object.getPrototypeOf(month), Object.getOwnPropertyDescriptors(month));

    model[year] = months.map(cloneMonth);
  }

  @Mutation
  RESET_MODELS() {
    this.preservedModel = {};
    this.model = {};
  }

  @Action({ rawError: true })
  async init() {
    await this.getMonths();
  }

  @Action({ rawError: true })
  async getMonths() {
    try {
      if (this.model[this.year]) {
        return this.model[this.year];
      }

      this.context.commit('UPDATE_IS_LOADING', true);
      const months = await getWorkMonths(this.year);
      const fullYear = await this.context.dispatch('setYear', { months, year: this.year });

      return fullYear;
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse)?.response?.data?.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      this.context.commit('UPDATE_IS_LOADING', false);
    }
  }

  @Action({ rawError: true })
  async setYear({ months, year }: YearModel) {
    const filteredMonths = months.filter((month) => month.start.match(/\d{4}-\d{2}-01/));
    const workMonths = await this.fillWholeYear({ months: filteredMonths, year });
    workMonths.sort((a: WorkMonth, b: WorkMonth) => (a.start > b.start ? 1 : -1));

    const preservedWorkMonths = workMonths.map((month) => ({ ...month }));

    this.UPDATE_MODEL({ months: workMonths, year, isPreserved: false });
    this.UPDATE_MODEL({ months: preservedWorkMonths, year, isPreserved: true });

    return workMonths;
  }

  @Action({ rawError: true })
  async fillWholeYear({ months, year }: YearModel) {
    const decorateMonth = (month: WorkMonth, moduleContext: WorkMonthsModule) => {
      const label = moment(month.start, 'YYYY-MM-DD').format('MMMM');
      const monthCopy = { ...month, workHours: month.workHours.toString(), label };

      Object.defineProperty(monthCopy, 'isChanged', {
        get: function () {
          const preservedValue =
            moduleContext.preservedModel[year].find((month) => month.start === this.start)?.workHours ?? 0;

          return +this.workHours !== +preservedValue;
        },
      });

      return monthCopy as WorkMonth & { label?: string };
    };

    const workMonths = Array.from(months).map((month) => decorateMonth(month, this));

    if (months.length !== 12) {
      const newMonths = [] as WorkMonth[];

      for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
        const monthString = (monthIndex + 1).toString().padStart(2, '0');
        const date = `${year}-${monthString}`;
        const isExistsMonth = months.find((month: WorkMonth) => month.start.includes(date));

        if (isExistsMonth) {
          continue;
        }

        const start = moment(date, 'YYYY-MM').format('YYYY-MM-DDT00:00:00');
        const newMonth = decorateMonth({ start, workHours: '0' }, this);

        newMonths.push(newMonth);
      }

      workMonths.push(...newMonths);
    }

    return workMonths;
  }

  @Action({ rawError: true })
  async changeYear(increment: boolean) {
    const changeOperand = increment ? 1 : -1;
    const newYear = this.year + changeOperand;

    await this.UPDATE_YEAR(newYear);

    return await this.getMonths();
  }

  @Action({ rawError: true })
  async saveYear() {
    try {
      const year = this.year;
      const preparedData = { year: year.toString() } as Record<string, string>;

      for (const month of this.model[year]) {
        month.workHours ||= '0';
        const monthNumber = +(month.start.match(/^\d{4}-(\d{2}).+?/)?.[1] ?? '1');
        const workHoursKey = `workHours[${monthNumber - 1}]`;

        preparedData[workHoursKey] = month.workHours;
      }

      this.context.commit('UPDATE_IS_SAVING', true);

      await updateYear(preparedData);
      this.UPDATE_MODEL({ months: this.model[year], year, isPreserved: true });

      ResponseHandlerModule.showNotify({
        message: 'Изменения сохранены',
        type: 'ok',
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse)?.response?.data?.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      this.context.commit('UPDATE_IS_SAVING', false);
    }
  }

  @Action({ rawError: true })
  async resetYear() {
    for (const monthIndex in this.preservedModel[this.year]) {
      const preservedMonth = this.preservedModel[this.year][monthIndex];
      preservedMonth.id = Math.random().toString();

      this.model[this.year][monthIndex] = Object.create(
        Object.getPrototypeOf(preservedMonth),
        Object.getOwnPropertyDescriptors(preservedMonth)
      );
    }
  }

  @Action({ rawError: true })
  resetStore() {
    this.RESET_MODELS();
    this.RESET_YEAR();
  }
}

export default getModule(WorkMonthsModule);
