import { makeFormRequest, makeGetRequest } from './requests';
import API from '@/config/api-routers';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { convertObjectToQueryParams } from '@/lib/Utils';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { FormResponse } from '@/interfaces/shared';
import { WorkMonth } from '@/interfaces/workMonth.interface';

export const getWorkMonths = async (year: string | number): Promise<WorkMonth[]> => {
  const filterString = '&' + convertObjectToQueryParams({ filters: [{ id: 'year', value: year }] });
  const assemblerUrl = new AssemblerUrl(API.workMonths.getYear);
  const url = assemblerUrl.addPage(1).addFilter(filterString).getUrl();
  const result = await makeGetRequest(url);

  return (result.data.result.table as TableApiInterface<WorkMonth>)?.rows ?? [];
};

export const updateYear = async (payload: Record<string, string>): Promise<FormResponse> => {
  const result = await makeFormRequest(API.workMonths.updateYear, payload);

  return result.data.result;
};
